<script>
import { makeProp } from "@/util/props";
import { isEmptyObject } from "@/util/inspect";
import { PROP_TYPE_OBJECT, PROP_TYPE_STRING } from "@/constants/props";
import { XFormInput } from "@/components/ui-components/form-input";
import { XModalCenter } from "@/components/ui-components/modal-center";
import { XFormSelect } from "@/components/ui-components/form-select";
import DatePicker from "vue2-datepicker";
import { v4ServiceApi } from "@/services/v4/v4.service";
export default {
  name: "SettingsTemplateModal",
  components: {
    XFormInput,
    XModalCenter,
    XFormSelect,
    DatePicker,
  },
  props: {
    upsertType: makeProp(PROP_TYPE_STRING, "create", (type) =>
      ["create", "edit"].includes(type)
    ),
    editItem: makeProp(PROP_TYPE_OBJECT, {
      type: null,
      period: null,
      status: null,
      message: {
        uz: "",
        ru: "",
      },
      time: "",
      expired_at: "",
    }),
    list: makeProp(PROP_TYPE_OBJECT, {
      types: [],
      periods: [],
      statuses: [],
    }),
  },
  emits: ["data-updated", "close-modal"],
  data() {
    const form = {
      type: null,
      period: null,
      status: "",
      message: {
        uz: "",
        ru: "",
      },
      time: "",
      expired_at: "",
    };
    return {
      applyButtonLoading: false,
      form,
      item: JSON.parse(JSON.stringify(form)),
    };
  },

  created() {
    if (this.upsertType === "edit") {
      this.setEditData();
    }
    setTimeout(() => this.$refs["creating-observer"].reset(), 10);
  },
  computed: {
    showStatusSelect() {
      return (
        this.upsertType === "edit" &&
        (this.editItem.status === "activated" ||
          this.editItem.status === "deactivated")
      );
    },
    statusOptions() {
      return Object.entries(this.list.statuses)
        .filter(([key]) => key === "activated" || key === "deactivated")
        .map(([key, value]) => ({
          value: key,
          text: value[this.$i18n.locale],
        }));
    },
    typeOptions() {
      return Object.entries(this.list.types).map(([key, value]) => ({
        value: key,
        text: value[this.$i18n.locale],
      }));
    },
    periodOptions() {
      return Object.entries(this.list.periods).map(([key, value]) => ({
        value: key,
        text: value[this.$i18n.locale],
      }));
    },
  },
  methods: {
    setEditData() {
      if (isEmptyObject(this.editItem)) {
        return;
      }
      this.item.time = this.editItem.time;
      this.item.expired_at = this.editItem.expired_at;
      this.item.message.ru = this.editItem.message.ru;
      this.item.message.uz = this.editItem.message.uz;

      this.item.type = this.editItem.type;
      this.item.status = this.editItem.status;
      this.item.period = this.editItem.period;
    },
    closeModal() {
      console.log(1);
      this.clearForm();
      setTimeout(() => this.$emit("close-modal"), 0);
    },
    startLoading() {
      this.applyButtonLoading = true;
    },
    finishLoading() {
      this.applyButtonLoading = false;
    },

    async saveItem() {
      const isSatisfied = await this.$refs["creating-observer"].validate();
      if (isSatisfied) {
        this.startLoading();
        const d = {
          type: this.item.type,
          period: this.item.period,
          message: this.item.message,
          time: this.item.time,
        };
        if (this.showStatusSelect) {
          d.status = this.item.status;
        }
        if (this.item.expired_at) {
          d.expired_at = this.item.expired_at;
        }
        if (this.upsertType === "edit") {
          d.id = this.editItem.id;
        }
        try {
          await v4ServiceApi.notificationTemplates[
            this.upsertType === "edit" ? "update" : "create"
          ](d);

          this.clearForm();
          this.$emit("data-updated");
        } catch (e) {
          this.toastedWithErrorCode(e);
        } finally {
          this.finishLoading();
        }
      }
    },
    clearForm() {
      this.item = JSON.parse(JSON.stringify(this.form));
      this.$refs["creating-observer"].reset();
    },
  },
};
</script>

<template>
  <x-modal-center
    :bilingual="true"
    apply-button-text="save"
    cancel-button-text="cancel"
    footer-class="d-flex justify-content-between x-gap-1"
    apply-button-class="w-100"
    cancel-button-class="w-100"
    :apply-button-loading="applyButtonLoading"
    :modal-container-style="{
      'max-width': '600px',
      width: '75%',
      overflowY: 'scroll',
    }"
    @close="closeModal"
    @cancel="closeModal"
    @apply="saveItem"
  >
    <template #header>
      <h3 class="x-font-size-36px font-craftworksans color-gray-600">
        {{ upsertType === "edit" ? $t("edit") : $t("add") }}
      </h3>
    </template>

    <template #body>
      <validation-observer
        ref="creating-observer"
        class="client-type-creating-body"
      >
        <!--  ? Type     -->
        <validation-provider
          v-slot="{ errors }"
          :name="$t('title')"
          rules="required"
          class="title-uz-provider"
        >
          <x-form-select
            v-model="item.type"
            :bilingual="true"
            :placeholder="$t('type')"
            :options="typeOptions"
          />
          <span v-if="errors[0]" class="error__provider">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <!--  ? Period     -->
        <validation-provider
          v-slot="{ errors }"
          :name="$t('period')"
          rules="required"
          class="title-uz-provider"
        >
          <x-form-select
            v-model="item.period"
            :bilingual="true"
            :placeholder="$t('period')"
            :options="periodOptions"
          />
          <span v-if="errors[0]" class="error__provider">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <!--  ? Text UZ     -->
        <validation-provider
          v-slot="{ errors }"
          :name="$t('message_uz')"
          rules="required"
          class="title-uz-provider"
        >
          <x-form-input
            v-model="item.message.uz"
            type="text"
            :placeholder="`${$t('message')} (${$t('placeholder_uz')})`"
            class="w-100"
          />
          <span v-if="errors[0]" class="error__provider">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <!--  ? Text RU     -->
        <validation-provider
          v-slot="{ errors }"
          :name="$t('message_ru')"
          rules="required"
          class="title-uz-provider"
        >
          <x-form-input
            v-model="item.message.ru"
            type="text"
            :placeholder="`${$t('message')} (${$t('placeholder_uz')})`"
            class="w-100"
          />
          <span v-if="errors[0]" class="error__provider">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <!-- STATUS -->
        <validation-provider
          v-slot="{ errors }"
          :name="$t('status')"
          rules="required"
          class="title-uz-provider"
          v-if="showStatusSelect"
        >
          <x-form-select
            v-model="item.status"
            :bilingual="true"
            :placeholder="$t('status')"
            :options="statusOptions"
          />
          <span v-if="errors[0]" class="error__provider">
            {{ errors[0] }}
          </span>
        </validation-provider>

        <div class="row">
          <div class="col-6">
            <div>Время:</div>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('time')"
              rules="required"
              class="title-uz-provider"
            >
              <date-picker
                valueType="format"
                type="datetime"
                v-model="item.time"
                style="width: 100%"
              />
              <span v-if="errors[0]" class="error__provider">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </div>
          <div class="col-6">
            <div>Действителен до:</div>
            <date-picker
              valueType="format"
              type="datetime"
              v-model="item.expired_at"
              style="width: 100%"
            />
          </div>
        </div>
      </validation-observer>
    </template>
  </x-modal-center>
</template>

<style lang="scss" scoped>
@import "./date-picker.css";

.client-type-creating-body {
  margin-top: 1rem;
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  font-family: Inter, sans-serif;
  color: var(--gray-600);
}

.icons-collection-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
}

.status-pick-color-title {
  font-size: 18px;
  color: var(--gray-400);
  font-weight: 500;
  font-family: Inter, serif;
}

.error__provider {
  color: red;
  font-size: 12px;
}
</style>
